import { createStore, update } from "nanostores";
import { StepsType } from "types/steps";
import { IAuth0User } from "pages";
import { CountryItem } from "components/CountrySelect/CountrySelect";

export interface IModalExpert {
  languages?: string[];
  tags?: string[];
  timeZone?: string;
  becomeExpert?: boolean;
  onBoardingLink?: string;
  expertId?: string;
  country?: CountryItem | null;
  step?: StepsType | null;
  user?: IAuth0User;
}

export const authModals = createStore<IModalExpert>(() => {
  authModals.set({});
});

export function updateAuthStore(data: IModalExpert) {
  update(authModals, (current) => ({ ...current, ...data }));
}
