import { InformationCircleIcon } from "@heroicons/react/outline";
import { InformationCircleIcon as InformationCircleIconSolid } from "@heroicons/react/solid";
import Tippy from "@tippyjs/react";

const Tooltip = ({
  tooltip,
  type = "default",
}: {
  tooltip?: string;
  type?: "default" | "solid";
}) => {
  if (!tooltip) {
    return null;
  }
  return (
    <Tippy content={<span className="inline-block p-2">{tooltip}</span>}>
      <span>
        {type === "default" ? (
          <InformationCircleIcon className="w-5 h-5 text-custom-secondary-blue inline cursor-pointer" />
        ) : (
          <InformationCircleIconSolid className="w-5 h-5 text-custom-dark-blue inline cursor-pointer" />
        )}
      </span>
    </Tippy>
  );
};

export default Tooltip;
