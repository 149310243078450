import { useState } from "react";
import { Helmet } from "react-helmet";
import { CheckIcon } from "@heroicons/react/solid";
import Image from "next/image";
import { translate } from "utils";
import Button from "components/Button/Button";

const RegistrationModal = ({ registerType }: { registerType: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const registerAs = registerType.includes("business") ? "expert" : "client";

  const handleClick = () => setIsLoading(true);

  return (
    <div className="p-11">
      <h2 className="text-xl font-bold mb-8 text-center">
        {translate("signUpAs")}
      </h2>
      <fieldset className="mb-8">
        <legend className="sr-only">{translate("signUpAs")}</legend>
        <div className="flex flex-col sm:flex-row justify-center gap-10">
          <label className="block bg-white cursor-pointer sm:flex sm:justify-between">
            <input
              type="radio"
              name="account"
              value={registerAs}
              className="sr-only"
              aria-labelledby="server-size-0-label"
              aria-describedby="server-size-0-description-0 server-size-0-description-1"
            />
            <div className="text-center">
              <div
                className={
                  "relative mb-4 rounded-full inline-block border-4  border-green-500 "
                }
              >
                <div className="flex rounded-full border-4 border-transparent">
                  {registerAs === "client" ? (
                    <Image
                      src="/img/onboarding-client.svg"
                      width={189}
                      height={189}
                      alt="image"
                    />
                  ) : (
                    <Image
                      src="/img/onboarding-expert.svg"
                      width={189}
                      height={189}
                      alt="image"
                    />
                  )}
                </div>
                <span className="absolute top-3 right-3 inline-flex items-center justify-center p-1 text-xs font-bold leading-none bg-green-500 rounded-full">
                  <CheckIcon
                    className="w-5 h-5 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="text-sm text-center">
                <p className="font-medium text-gray-900">
                  {translate(registerAs)}
                </p>
                <div className="text-gray-500">
                  <p className="sm:inline">
                    {registerAs === "client"
                      ? translate("registerAsClient")
                      : translate("registerAsExpert")}
                  </p>
                </div>
              </div>
            </div>
          </label>
        </div>
      </fieldset>
      <div className="m-auto">
        <a
          href={`/api/auth/login?screen_hint=signup&flow=${registerAs}`}
          className="focus:outline-none"
        >
          <Button fluid loading={isLoading} onClick={handleClick}>
            {translate("next")}
          </Button>
        </a>
      </div>
      <Helmet>
        <title>{translate("signUpTitle")}</title>
      </Helmet>
    </div>
  );
};

export default RegistrationModal;
