import { translate } from "utils";
import Button from "components/Button/Button";
import { ArrowNarrowRightIcon } from "@heroicons/react/solid";

type Profile = "expert" | "client" | "public" | undefined;

const ErrorView = ({ profile }: { profile?: Profile }) => {
  const link =
    profile === "expert"
      ? "/my/profile"
      : profile === "client"
      ? "/my/following"
      : "/";
  return (
    <div className="flex flex-col items-center pt-24 xs:pt-52">
      <svg
        width="261"
        height="204"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity=".1" cx="136.499" cy="102.5" r="101.5" fill="#fff" />
        <path
          d="M247.123 152.26c-8.397-11.69-46.264-22.233-92.143-24.855s-84.734 3.551-94.393 14.148l-2.03-.109-.275 4.862v.163c-.768 13.602 41.27 27.04 93.899 30.044 52.63 3.005 95.93-5.517 96.753-19.118h.164l.22-5.026-2.195-.109Z"
          fill="#CEDCE0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.279 122.524c52.649 3.048 94.692 16.531 93.905 30.114-.786 13.584-44.104 22.124-96.753 19.076-52.65-3.049-94.692-16.532-93.905-30.115.786-13.583 44.104-22.124 96.753-19.075 0 0 52.649 3.048 0 0Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M154.372 137.733c23.208 1.343 41.74 7.291 41.393 13.285-.347 5.994-19.442 9.763-42.65 8.419-23.208-1.343-41.74-7.292-41.393-13.285.347-5.994 19.442-9.763 42.65-8.419 0 0 23.208 1.343 0 0Z"
          fill="#CFD3D6"
          opacity=".5"
        />
        <path
          d="M114.039 8.05C72.77 21.98 49.227 64.26 57.843 105.665l.11-.054c0 .054 0 .054.054.109 3.897 11.471 42.971 8.631 87.204-6.282s76.996-36.326 73.1-47.797c0-.054-.055-.054-.055-.11h.055c-18.44-38.127-63.002-57.41-104.272-43.48Z"
          fill="url(#a)"
        />
        <path
          d="M110.967.457c-3.128 1.202-4.885 4.752-3.897 7.975a6.582 6.582 0 0 0 2.141 3.223c1.097.929 1.975 2.13 2.414 3.496v.055a3.315 3.315 0 0 0 4.226 2.075l1.976-.655c1.811-.601 2.744-2.513 2.14-4.316-.494-1.365-.494-2.895-.165-4.315a6.52 6.52 0 0 0-.164-3.55C118.43.893 114.534-.91 110.967.456Z"
          fill="#3B5059"
        />
        <path
          d="M222.482 69.448c.219-.383.713-.546 1.042-.273l7.299 5.134c.384.274.384.82.055 1.148l-16.299 13.11c-.604.491-1.427-.164-1.098-.874l9.001-18.245ZM239.713 76.277c.385-.273.879-.164 1.098.273l4.116 7.92c.22.438 0 .93-.494 1.038l-20.525 4.261c-.823.055-1.262-.928-.604-1.365l16.409-12.127ZM237.025 92.938c.439-.11.878.219.878.656l.274 5.244c0 .491-.384.82-.878.764l-12.073-2.622c-.769-.163-.769-1.201 0-1.42l11.799-2.622Z"
          fill="#EA5046"
        />
        <path
          d="M218.146 56.285c-36.769 31.3-95.38 46.158-126.771 42.771-22.117-2.403-27.44-24.035-26.782-47.524-7.957 16.388-10.701 35.397-6.75 54.134l.11-.055c0 .055 0 .055.055.109 3.896 11.526 42.971 8.686 87.203-6.227 38.855-13.11 68.819-31.19 72.935-43.208Z"
          fill="#DFE3E6"
        />
        <path
          d="M9.988 112.166c11.525 4.424 20.745 10.979 26.727 19.009l24.53 32.994c.55.765.056 1.748-1.097 2.185l-13.775 5.408c-1.152.437-2.579.218-3.128-.547L.44 112.002c-.988-1.366 1.482-2.95 3.458-2.185l6.091 2.349Z"
          fill="url(#b)"
        />
        <path
          d="M75.57 189.297 59.49 167.72l-10.592 4.206-2.634-1.694v2.732l17.616 23.652c1.756 2.349 6.146 3.114 9.768 1.639 2.635-1.038 4.171-2.95 4.171-4.807v-2.677l-2.25-1.474Z"
          fill="#041724"
        />
        <path
          d="M77.106 188.586 59.49 164.988l-13.226 5.19L63.88 193.83c1.756 2.349 6.146 3.114 9.769 1.639 3.677-1.42 5.213-4.534 3.457-6.883Z"
          fill="#4F6B78"
        />
        <path
          d="M255.3 127.57c-.439.82-1.427 1.202-2.305.82-.988-.383-1.427-1.585-.933-2.513l2.744-5.08c.384-.656.055-1.53-.659-1.803-.603-.273-1.317 0-1.646.601l-3.018 5.626c-.439.82-1.427 1.202-2.305.82-.988-.383-1.427-1.584-.933-2.513l2.744-5.08c.384-.656.055-1.53-.659-1.803-.604-.273-1.317 0-1.646.601l-3.787 7.101-.384.71-.549 1.038c-.878 1.639-.11 3.551 1.701 4.643l-24.695 44.629c-.988 1.803.109 4.042 2.414 4.971 2.305.929 4.939.218 5.927-1.584l23.489-45.12c2.085.546 4.335-.219 5.213-1.803l.549-1.038.384-.71 3.677-6.828c.384-.656.055-1.53-.658-1.803a1.198 1.198 0 0 0-1.647.492l-3.018 5.626Z"
          fill="url(#c)"
        />
        <path
          opacity=".3"
          d="m40.611 136.418-3.896-5.244c-1.208-1.584-2.525-3.168-3.952-4.643L9.66 124.729l8.397 11.689H40.61ZM23.324 143.684l3.567 4.916h22.775l-3.676-4.916H23.323Z"
          fill="#E5E5E5"
        />
        <defs>
          <linearGradient
            id="a"
            x1="56.075"
            y1="3.526"
            x2="56.075"
            y2="112.767"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#fff" />
            <stop offset="1" stopColor="#EAE7E7" />
          </linearGradient>
          <linearGradient
            id="b"
            x1=".214"
            y1="109.617"
            x2=".214"
            y2="171.984"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A2AFB5" />
            <stop offset="1" stopColor="#BEC8CC" />
          </linearGradient>
          <linearGradient
            id="c"
            x1="218.606"
            y1="116.539"
            x2="218.606"
            y2="180.746"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A2AFB5" />
            <stop offset="1" stopColor="#BEC8CC" />
          </linearGradient>
        </defs>
      </svg>
      <div className="max-w-xs xs:max-w-sm text-center">
        <h2 data-testid="errorPage" className="mt-20 font-medium text-xl">
          {translate("errorViewHeader")}
        </h2>
        <p className="pt-4 text-sm text-gray-500">
          {translate("errorViewDescription")}
        </p>
        <Button className="mt-4" onClick={() => window.location.assign(link)}>
          {translate("returnToHome")}
          <ArrowNarrowRightIcon className="ml-1 w-6 h-5 -mr-1" />
        </Button>
      </div>
    </div>
  );
};

export default ErrorView;
