import { useRef, useEffect, useState } from "react";
import cx from "classnames";
import {
  checkIsImage,
  checkIsVideo,
  getHostName,
  convertVimeoUrl,
  isAudioFormat,
} from "helpers";
import { getFileIconWithColor } from "utils";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import Hls from "hls.js";

declare global {
  interface Window {
    hls: any;
  }
}

const OfferAttachment = ({
  attachemntUrl = null,
  mediaUrl = null,
  fileName = "",
  type = "default",
  isBlurred = false,
  iconSize,
  adaptivePlaylist,
  adaptivePlaylistCover,
  cover,
  section,
  isBought,
  isRounded,
}: {
  attachemntUrl?: string;
  mediaUrl?: string;
  fileName?: string;
  type?: "default" | "full";
  isBlurred?: boolean;
  iconSize?: boolean | null;
  adaptivePlaylist?: boolean;
  adaptivePlaylistCover?: boolean;
  cover?: boolean;
  section?: boolean;
  isBought?: boolean;
  isRounded?: boolean;
}) => {
  const playerContainer = useRef(null);
  const player = useRef(null);
  const [poster, setPoster] = useState("");
  const [playerReady, setPlayerReady] = useState(false);

  type PlayerOptions = {
    fullscreen: { enabled: boolean; fallback: boolean; iosNative: boolean };
    vimeo: { fullscreen: boolean };
    controls: string[];
    youtube: {
      noCookie: boolean;
      rel: number;
      showinfo: number;
      iv_load_policy: number;
    };
    quality?: {
      default: number;
      options: number[];
      forced: boolean;
      onChange: (e: any) => void;
    };
    i18n?: { qualityLabel: { [key: number]: string } };
  };

  useEffect(() => {
    function updateQuality(newQuality) {
      if (newQuality === 0) {
        window.hls.currentLevel = -1;
      } else {
        window.hls.levels.forEach((level, levelIndex) => {
          if (level.height === newQuality) {
            // console.log("Found quality match with " + newQuality);
            window.hls.currentLevel = levelIndex;
          }
        });
      }
    }
    if (playerContainer.current && !isPlaylistLink(mediaUrl)) {
      const defaultOptions: PlayerOptions = {
        fullscreen: { enabled: true, fallback: true, iosNative: true },
        vimeo: { fullscreen: true },
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          isAudio && "mute",
          "volume",
          "captions",
          "settings",
          "fullscreen",
        ],
        youtube: {
          noCookie: true,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
        },
      };
      let hls;
      if (
        !Hls.isSupported() ||
        (section === true &&
          (adaptivePlaylist === false ||
            adaptivePlaylist === null ||
            adaptivePlaylist === undefined)) ||
        (cover === true &&
          (adaptivePlaylistCover === false ||
            adaptivePlaylistCover === null ||
            adaptivePlaylistCover === undefined)) ||
        mediaUrl
      ) {
        player.current = new Plyr(playerContainer.current, defaultOptions);
        setPlayerReady(true);
      } else {
        const source = `${attachemntUrl}/master_playlist.m3u8`;
        hls = new Hls({ autoStartLoad: false });
        hls.loadSource(source);
        hls.attachMedia(playerContainer.current);
        window.hls = hls;

        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          const availableQualities = hls.levels.map((l) => l.height);
          availableQualities.unshift(0);

          defaultOptions.quality = {
            default: 0,
            options: availableQualities,
            forced: true,
            onChange: (e) => updateQuality(e),
          };

          defaultOptions.i18n = {
            qualityLabel: {
              0: "Auto",
            },
          };

          hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
            var span = document.querySelector(
              ".plyr__menu__container [data-plyr='quality'][value='0'] span"
            );
            if (hls.autoLevelEnabled) {
              span.innerHTML = `AUTO (${hls.levels[data.level].height}p)`;
            } else {
              span.innerHTML = `AUTO`;
            }
          });

          player.current = new Plyr(playerContainer.current, defaultOptions);
          if (player.current) {
            player.current.on("play", () => {
              hls.startLoad();
            });
          }
          setPlayerReady(true);
        });
      }

      if (player.current) {
        player.current.on("play", () => {
          isVimeo && player.current.fullscreen.enter();
        });

        player.current.on("loadedmetadata", () => {
          setPoster("#t=0.5");
        });

        player.current.on("enterfullscreen", () => {
          if (/iPhone/i.test(navigator.userAgent)) {
            let videoElement = document.querySelector(
              ".plyr-component-class .plyr:not(.plyr--fullscreen, .plyr:fullscreen) video"
            ) as HTMLElement;
            if (videoElement) {
              videoElement.style.display = "block";
              videoElement.style.objectFit = "contain";
              videoElement.style.width = "auto";
              videoElement.style.height = "100%";
              videoElement.style.margin = "0 auto";
            }
          }
        });

        player.current.on("exitfullscreen", () => {
          if (player.current.fullscreen.active) {
            player.current.fullscreen.exit();
          }
          if (/iPhone/i.test(navigator.userAgent)) {
            let videoElement = document.querySelector(
              ".plyr-component-class .plyr:not(.plyr--fullscreen) video"
            ) as HTMLElement;
            if (videoElement) {
              videoElement.style.display = "block";
              videoElement.style.objectFit = "contain";
              videoElement.style.width = "auto";
              videoElement.style.height = "208px";
              videoElement.style.margin = "0 auto";
            }
          }
        });
      }
    }

    return () => {
      if (player.current) {
        player.current.destroy();
      }
    };
  }, []);

  function isPlaylistLink(url) {
    const playlistKeywords = /(playlist|list=)/i;
    return playlistKeywords.test(url);
  }

  function getPlaylistIdFromUrl(url) {
    const playlistIdRegex = /[?&]list=([^&]+)/;
    const match = url.match(playlistIdRegex);

    return match ? match[1] : null;
  }

  const fileType = attachemntUrl?.split(".")?.slice(-1)?.[0];
  const isAttachedVideo = checkIsVideo(fileType);
  const isImg = checkIsImage(fileType);
  const isAudio = isAudioFormat(fileType);
  const isDocument = !isAttachedVideo && !isImg && !isAudio;
  const hostName = getHostName(
    attachemntUrl !== null ? attachemntUrl : mediaUrl
  );
  const isVimeo = hostName === "vimeo";
  const isVimeoOrYoutube = hostName === "youtube" || isVimeo;
  const { icon } = getFileIconWithColor(fileName);
  const viemoTransformLink = isVimeo ? convertVimeoUrl(mediaUrl) : null;
  const isFullHeight = type === "full";

  const youtubeDataApiV3Key = "AIzaSyCNVhS92rh8XYV6Y9diXro54dKpuh_pvEI";
  const youtubePlaylistId = isVimeoOrYoutube
    ? getPlaylistIdFromUrl(mediaUrl)
    : null;

  const isList = isVimeoOrYoutube
    ? /list=/i.test(mediaUrl) && !/playlist/i.test(mediaUrl)
    : null;

  const [videos, setVideos] = useState([]);
  const [nowPlaying, setNowPlaying] = useState(null);
  const playerContainerRef = useRef();

  useEffect(() => {
    getVideos();
  }, []);

  const playerRef = useRef<Plyr | null>(null);
  const [isShowCarusel, setShowCarusel] = useState(true);
  useEffect(() => {
    if (isPlaylistLink(mediaUrl)) {
      playerRef.current = new Plyr(playerContainerRef.current, {
        fullscreen: { enabled: true, fallback: true, iosNative: true },
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "mute",
          "volume",
          "captions",
          "settings",
          "pip",
          "airplay",
          "fullscreen",
        ],
      });
      playerRef.current.on("play", () => {
        setShowCarusel(false);
      });
      playerRef.current.on("pause", () => {
        setShowCarusel(true);
      });
    }
  }, [nowPlaying]);

  async function getVideos() {
    if (youtubePlaylistId) {
      let response = await fetch(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${youtubePlaylistId}&key=${youtubeDataApiV3Key}`
      );
      let data = await response.json();
      let videos = data.items;
      if (videos) {
        videos = videos.filter(
          (video) => video.snippet.title !== "Private video"
        );
        videos = videos.map((video) => ({
          id: video.snippet.resourceId.videoId,
          title: video.snippet.title,
        }));
        setNowPlaying({
          ...videos[0],
          index: 0,
        });
        setVideos((prevVideos) => [...prevVideos, ...videos]);
      }
    }
  }

  function playVideo(index) {
    setNowPlaying((prevState) => ({
      ...prevState,
      ...videos[index],
      index,
    }));
    if (playerRef.current) {
      playerRef.current.source = {
        type: "video",
        sources: [
          {
            src: videos[index].id,
            provider: "youtube",
          },
        ],
      };
    }
  }

  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    if (
      (adaptivePlaylist === true && section === true && isBought === true) ||
      (adaptivePlaylistCover === true && cover === true)
    ) {
      let img = new Image();

      img.onload = function (this: HTMLImageElement) {
        if (this.width < this.height) {
          setIsPortrait(true);
        }
      };

      img.src = `${attachemntUrl}/poster.jpg`;
    }
  }, []);

  const posterHls =
    ((adaptivePlaylist === false ||
      adaptivePlaylist === null ||
      adaptivePlaylist === undefined) &&
      section === true &&
      isBought === undefined) ||
    ((adaptivePlaylistCover === false ||
      adaptivePlaylistCover === null ||
      adaptivePlaylistCover === undefined) &&
      cover === true) ||
    attachemntUrl === null
      ? null
      : attachemntUrl + "/poster.jpg";

  return attachemntUrl || (mediaUrl && hostName !== "error") ? (
    isAttachedVideo || (!attachemntUrl && mediaUrl) || isAudio ? (
      <div
        className={cx("relative rounded-lg", {
          "h-auto w-full": isVimeo,
          "before:absolute before:inset-0 before:bg-white before:z-20 before:opacity-95 pointer-events-none":
            isBlurred,
          "overflow-hidden h-[131px] w-[131px] plyr-component-class-small":
            isRounded,
          "plyr-component-class": !isRounded,
        })}
        data-is-video={true}
      >
        {nowPlaying && (
          <div className="videoContainer">
            <div
              className="plyr__video-embed"
              ref={playerContainerRef}
              data-is-video="true"
              key={nowPlaying.id}
            >
              <iframe
                src={`https://www.youtube.com/embed/${nowPlaying.id}`}
                allowFullScreen
              />
            </div>

            {isShowCarusel && !isList ? (
              <div className="plyrPlaylistWrapper">
                <ul className="plyrPlaylist">
                  {videos.map((video, index) => (
                    <li
                      className={`playlistItem ${
                        index === nowPlaying.index ? "plsPlaying" : ""
                      }`}
                      onClick={() => playVideo(index)}
                      key={index}
                    >
                      <img
                        src={`https://i.ytimg.com/vi/${video.id}/mqdefault.jpg`}
                        alt={video.title}
                        className="plyrMiniPoster"
                      />
                      <p>{video.title}</p>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        )}

        {isVimeoOrYoutube &&
        isPlaylistLink(mediaUrl) ? null : isVimeoOrYoutube ? (
          <div
            className="plyr__video-embed"
            ref={playerContainer}
            data-is-video="true"
          >
            <iframe
              src={attachemntUrl || (isVimeo ? viemoTransformLink : mediaUrl)}
              allowFullScreen
              allowTransparency
              allow="autoplay"
            />
          </div>
        ) : isAudio ? (
          <audio ref={playerContainer} controls>
            <source src={attachemntUrl} type="audio/mp3" />
          </audio>
        ) : (
          <div
            className={`${isRounded ? "plyr-small-card" : ""}${
              isPortrait &&
              !isRounded &&
              ((adaptivePlaylist === true && section === true) ||
                (adaptivePlaylistCover === true && cover === true))
                ? "video-wrapper"
                : ""
            }`}
            style={{
              backgroundImage:
                !isPortrait &&
                (((adaptivePlaylist === false ||
                  adaptivePlaylist === null ||
                  adaptivePlaylist === undefined) &&
                  section === true) ||
                  ((adaptivePlaylistCover === false ||
                    adaptivePlaylistCover === null ||
                    adaptivePlaylistCover === undefined) &&
                    cover === true))
                  ? `none`
                  : `url("${attachemntUrl}/poster.jpg")`,
              background:
                !isPortrait ||
                ((adaptivePlaylist === false ||
                  adaptivePlaylist === null ||
                  adaptivePlaylist === undefined) &&
                  section === true) ||
                ((adaptivePlaylistCover === false ||
                  adaptivePlaylistCover === null ||
                  adaptivePlaylistCover === undefined) &&
                  cover === true)
                  ? `black`
                  : `url("${attachemntUrl}/poster.jpg")`,
            }}
          >
            <video
              ref={playerContainer}
              data-is-video="true"
              src={attachemntUrl || mediaUrl}
              controls
              data-poster={posterHls}
              poster={posterHls}
            />
          </div>
        )}
      </div>
    ) : isDocument ? (
      <a
        href={isBlurred ? "" : attachemntUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={cx({ "opacity-5 pointer-events-none": isBlurred })}
      >
        <div className="flex items-center justify-center flex-col gap-2 text-center">
          <div
            className={
              iconSize
                ? "w-[50px] h-[70px] drop-shadow-2xl opacity-80"
                : "w-[72px] h-[92px] drop-shadow-2xl opacity-80"
            }
          >
            {icon}
          </div>
          <p
            className="text-left text-sm font-semibold truncate"
            title={fileName}
          >
            {fileName}
          </p>
        </div>
      </a>
    ) : (
      <img
        src={attachemntUrl}
        alt="attachemntUrl"
        className={cx("mx-auto max-w-full", {
          "h-auto": isFullHeight,
          "max-h-[60vh]": !isFullHeight,
          "opacity-5 pointer-events-none": isBlurred,
          "rounded-lg object-cover overflow-hidden h-[131px] w-[131px]":
            isRounded,
        })}
      />
    )
  ) : null;
};

export default OfferAttachment;
