import { createStore } from "nanostores";
import Cookies from "js-cookie";

export const userRoleStore = createStore<"client" | "expert" | null>(() => {
  const savedRole = Cookies.get("userRole");
  userRoleStore.set(
    savedRole === "client" || savedRole === "expert" ? savedRole : null
  );
});

export const setClientRole = () => {
  userRoleStore.set("client");
  Cookies.set("userRole", "client");
};

export const setExpertRole = () => {
  userRoleStore.set("expert");
  Cookies.set("userRole", "expert");
};
