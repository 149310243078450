import { config } from "utils";
import React, { Fragment, useState, useEffect } from "react";
import { translate } from "utils";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { GlobeIcon } from "@heroicons/react/outline";
import cx from "classnames";
import { Listbox, Transition } from "@headlessui/react";
import { api } from "utils/api";

export interface CountryItem {
  label: string;
  code: string;
}

export function CountrySelect({
  handleSelect,
  referralCodeCountry,
  promoCode,
}: {
  handleSelect(selected: CountryItem | null): void;
  referralCodeCountry?: any;
  promoCode?: any;
}) {
  const [selected, setSelected] = useState<CountryItem | null>(null);
  const [geolocation, setGeolocation] = useState({
    label: null,
    code: null,
  });
  const [countries, setCountries] = useState([]);
  const [geolocationLoaded, setGeolocationLoaded] = useState(false);

  const handleChange = (country: CountryItem | null) => {
    handleSelect(country);
    setSelected(country);
  };

  useEffect(() => {
    if (!promoCode && !geolocationLoaded) {
      api
        .get("/geolocation")
        .then((data) => {
          setGeolocation({
            label: data?.label === "Ukraine" ? "United States" : data?.label,
            code: data?.country === "UA" ? "US" : data?.country,
          });
          setGeolocationLoaded(true);
        })
        .catch((error) => {
          console.error("Ошибка загрузки геолокации:", error);
        });
    }
  }, [promoCode, geolocationLoaded]);

  useEffect(() => {
    if (geolocationLoaded && !promoCode) {
      handleChange({
        label: geolocation.label,
        code: geolocation.code,
      });
    }
  }, [geolocationLoaded, promoCode]);

  useEffect(() => {
    handleChange({
      label:
        referralCodeCountry?.country === "UA" && promoCode !== ""
          ? "United States"
          : geolocation.label,
      code:
        referralCodeCountry?.country === "UA" && promoCode !== ""
          ? "US"
          : geolocation.code,
    });
    setCountries(
      // referralCodeCountry?.country === "UA" && promoCode !== ""
      //   ? [config.stripeSupportedCountries.find((el) => el.code === "UA")]
      //   : referralCodeCountry?.country !== "UA" && promoCode !== ""
      //   ? config.stripeSupportedCountries.filter((el) => el.code !== "UA")
      //   : config.stripeSupportedCountries
      config.stripeSupportedCountries.filter((el) => el.code !== "UA")
    );
  }, [referralCodeCountry, promoCode]);

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          <div className="relative mt-1 w-full">
            <Listbox.Button
              data-testid="select-country-button"
              className="relative w-full cursor-default rounded-md text-white border border-slate-200 h-[44px] pl-3 pr-10 text-left shadow-sm  focus:outline-none focus:ring-0 sm:text-sm"
            >
              <span className="flex items-center">
                <GlobeIcon className="h-5 w-5 text-slate-500" />
                {selected?.label !== null ? (
                  <span className="ml-3 block truncate font-semibold text-slate-900">
                    {selected?.label}
                  </span>
                ) : (
                  <span className="ml-3 text-slate-900 font-semibold">
                    {translate("chooseCountry")}...
                  </span>
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                data-testid="country-list"
                className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white border border-slate-300 text-gray-700  py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {countries.map((country) => (
                  <Listbox.Option
                    key={country.label}
                    className={({ active }) =>
                      cx(
                        active
                          ? "text-slate-900 bg-slate-100"
                          : "text-slate-700",
                        "relative select-none py-2 pl-3 pr-9 cursor-pointer"
                      )
                    }
                    value={country}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={cx(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {country.label}
                          </span>
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
