import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import { useStore } from "nanostores/react";
import { StepsType } from "types/steps";
import { authModals, updateAuthStore } from "stores/authModals";
import ExpertCountryModal from "components/AuthModals/components/ExpertCountryModal";
import BecomeExpert from "./components/BecomeExpert";
import ExpertNotSupported from "./components/ExpertNotSupported";
import RegistrationModal from "./components/RegistrationModal";
import InviteClientsModal from "components/InviteClientsModal/InviteClientsModal";
import { XIcon } from "@heroicons/react/outline";

export type AccountType = "client" | "expert";

function AuthModals({ isLoggedUser }: { isLoggedUser?: boolean }) {
  const store = useStore(authModals);
  const router = useRouter();
  const query = router.query;
  const pathName = router.pathname;
  const step = (store.step || query.step) as StepsType | string | null;
  let isOpen = Boolean(step);
  const completeRef = useRef(null);

  const handleClose = () => {
    const isBecomeExpertFlow = step === "country" && store.becomeExpert;
    const isCreateExpertFromPrivateLanding = step === "country" && isLoggedUser;

    const isBookingOfferAuthFlow = query?.step !== "auth" && step === "auth";

    if (isBecomeExpertFlow || isBookingOfferAuthFlow) {
      return updateAuthStore({ step: null });
    }

    if (isCreateExpertFromPrivateLanding) {
      return router.replace("/");
    }

    if (step === "auth") {
      return query?.publicId && query?.offerId
        ? router.replace({
            query: { publicId: query?.publicId, offerId: query?.offerId },
          })
        : router.push({
            pathname: pathName,
          });
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        static
        open={isOpen}
        onClose={handleClose}
        initialFocus={completeRef}
      >
        <div
          className="flex items-center justify-center w-full min-h-screen px-4 text-center"
          ref={completeRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"
              aria-hidden="true"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className={`${
              step === "invite-clients" && "h-screen"
            } inline-block sm:h-screen align-middle`}
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="max-w-[375px] inline-block w-full my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              {isOpen && (
                <button
                  className="absolute top-[10px] right-4 text-gray-500 cursor-pointer"
                  onClick={() => {
                    updateAuthStore({ step: null });
                  }}
                >
                  <XIcon className="w-6 h-6 text-slate-400" />
                </button>
              )}

              <Modal step={step} registerType={pathName} />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

function Modal({
  step,
  registerType,
}: {
  step: StepsType | string | null;
  registerType: string;
}) {
  switch (step) {
    case "becomeExpert":
      return <BecomeExpert />;
    case "expertNotSupported":
      return <ExpertNotSupported />;
    case "country":
      return <ExpertCountryModal />;
    case "auth":
      return <RegistrationModal registerType={registerType} />;
    case "invite-clients":
      return <InviteClientsModal />;
    default:
      return null;
  }
}

export default AuthModals;
