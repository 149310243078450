import { DuplicateIcon } from "@heroicons/react/outline";
import { useQuery } from "@tanstack/react-query";
import { api } from "utils/api";
import { Me } from "types/user";
import { translate, toast } from "utils";
import { updateAuthStore } from "stores/authModals";
import Button from "components/Button/Button";
import Field from "components/Field/Field";
import Loader from "components/Loader/Loader";

function InviteClientsModal() {
  const tipsModal = document.getElementById("tips-modal");

  tipsModal?.classList.add("hidden", "md:block");

  const { data, isLoading } = useQuery<Me>(["me"], () =>
    api.get("/users/v1/users/me")
  );
  const publicId = data?.expert?.publicId;

  const handleClose = () => {
    updateAuthStore({ step: null });
    tipsModal?.classList.remove("hidden", "md:block");
  };

  const handleCopy = () => {
    window.navigator.clipboard.writeText(
      `${window.location.origin}/${publicId}`
    );
    toast(translate("toastUrlCopied"), {
      type: "success",
    });
  };

  const handleClick = () => {
    handleCopy();
    handleClose();
  };

  if (isLoading) {
    return <Loader fullScreen={false} />;
  }

  return (
    <div className="p-6 m-auto">
      <div className="divide-y">
        <h2 className="text-lg font-medium mb-4">
          {translate("inviteClientsModalInviteClients")}
        </h2>
        <div className="flex flex-col gap-4 pt-6">
          <div>
            <Field
              label={`${translate("copyYourLink")}`}
              name="link"
              fluid
              disabled
              onChange={() => {}}
              value={`${window?.location.origin}/${publicId}`}
              icon={{
                component: (
                  <DuplicateIcon
                    onClick={handleCopy}
                    className="h-5 w-5 opacity-50 clipboard"
                  />
                ),
              }}
            />
            <p className="text-sm opacity-50 mt-1.5">
              {translate("shareYourLink")}
            </p>
          </div>
          <Button className="my-2 sm:my-6" fluid onClick={handleClick}>
            {translate("done")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default InviteClientsModal;
