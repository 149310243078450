import { image, video, document } from "constants/fileTypes";
import { useMemo } from "react";

export const checkIsVideo = (fileType: string) => {
  const fileTypeLowerCase = fileType?.toLowerCase();

  return video.includes(fileTypeLowerCase);
};

export const getHostName = (url: string) => {
  if (!url) {
    return "other";
  }

  const { hostname, pathname } = new URL(
    url,
    "https://wrong-waylight-url.info"
  );

  if (hostname === "wrong-waylight-url.info") {
    return "error";
  }

  if (pathname === "/") {
    return "other";
  }

  // if (url.indexOf("youtube.com/playlist") > -1) {
  //   return "other";
  // }

  switch (hostname) {
    case "vimeo.com":
    case "www.vimeo.com":
      return "vimeo";
    case "youtube.com":
    case "www.youtube.com":
    case "youtu.be":
      return "youtube";
    default:
      return "other";
  }
};

export function convertVimeoUrl(url) {
  const splitUrl = url.split("/");
  const videoId = splitUrl[splitUrl.length - 1];
  return "https://player.vimeo.com/video/" + videoId;
}

export const checkIsImage = (fileType: string) => {
  const fileTypeLowerCase = fileType?.toLowerCase();

  return image.includes(fileTypeLowerCase);
};
export const checkIsDocument = (fileType: string) => {
  const fileTypeLowerCase = fileType?.toLowerCase();

  return document.includes(fileTypeLowerCase);
};
export function isAudioFormat(extension) {
  const audioFormats = ["mp3", "wav", "aac", "ogg", "webm"];
  return audioFormats?.includes(extension?.toLowerCase());
}

export const useExpertBlobsFields = (galleryValue: any = []) => {
  // portfolioValue, headerImage, headerVideo
  const portfolioValue: any[] = [];
  const headerImages: any[] = [];
  const headerVideos: any[] = [];

  galleryValue.forEach((file: any) => {
    if (file?.flags?.headerImage) {
      headerImages.push(file);
      return;
    }

    if (file?.flags?.headerVideo) {
      headerVideos.push(file);
      return;
    }

    portfolioValue.push(file);
  });

  return useMemo(
    () => ({
      portfolioValue,
      headerImages,
      headerVideos,
    }),
    [galleryValue]
  );
};
