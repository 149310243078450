import Button from "components/Button/Button";
import { translate } from "utils";
import Image from "next/image";
import { updateAuthStore } from "stores/authModals";

function BecomeExpert() {
  const handleNext = () => {
    updateAuthStore({ step: "country", becomeExpert: true });
  };

  const handleCancel = () => {
    updateAuthStore({ step: null });
  };

  return (
    <div className="p-6">
      <h2 className="text-lg font-medium mb-4">
        {translate("becomeAnExpert") + ":"}
      </h2>
      <div>
        <p className="text-lg mb-6 text-center">
          {translate("beginOnboardingAsExpert")}
        </p>
        <div className="my-10 text-center">
          <Image
            src="/img/rocket-launch.svg"
            alt="rocket"
            width={100}
            height={100}
          />
        </div>
      </div>

      <div className="flex gap-4 flex-wrap">
        <Button onClick={handleNext} fluid>
          {translate("next")}
        </Button>
        <Button onClick={handleCancel} fluid type="secondary">
          {translate("buttonCancel")}
        </Button>
      </div>
    </div>
  );
}

export default BecomeExpert;
