import { useState } from "react";
import { useHistory } from "react-router-dom";
import Head from "next/head";
import { updateAuthStore } from "stores/authModals";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { translate, toast, getCity } from "utils";
import { api } from "utils/api";
import Button from "components/Button/Button";
import {
  CountryItem,
  CountrySelect,
} from "components/CountrySelect/CountrySelect";
import { ReceiptTaxIcon } from "@heroicons/react/outline";
import { setExpertRole } from "stores/userRoleStore";
import Cookies from "js-cookie";

export default function ExpertCountryModal() {
  const queryClient = useQueryClient();
  const [selectedCountry, setSelectedCountry] = useState<CountryItem | null>(
    null
  );
  const [promoCode, setPromoCode] = useState("");
  const history = useHistory();
  const { mutateAsync: addExpert, isLoading: loading } = useMutation(
    (expert: {
      country: string;
      timeZone: string;
      city: string;
      referralCode?: string;
    }) => api.post("/experts/v1/create", expert)
  );

  const createExpert = async () => {
    try {
      const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      await addExpert({
        country: selectedCountry?.code,
        timeZone: localTimeZone,
        city: getCity(localTimeZone),
        referralCode: promoCode,
      });
      await queryClient.invalidateQueries(["me"]);

      setExpertRole();
      Cookies.set("createExpert", "expert");
      history?.push("/my/thankyou");
      updateAuthStore({ step: null });
    } catch (err) {
      console.log(err);
      toast(translate("errorViewHeader"), { type: "error" });
    }
  };

  const isDisabled = loading || !selectedCountry?.label;

  const { data: referralCodeCountry, refetch } = useQuery(
    ["referral-code-country"],
    () => api.get(`/offers/v1/subscription-offer/referral-code/${promoCode}`),
    {
      enabled: !!promoCode,
      cacheTime: 0,
    }
  );

  return (
    <div className="p-6 max-w-md">
      <h2 className="text-lg font-medium mb-1">
        {translate("createWaylightBusiness")}
      </h2>
      <div className="text-slate-500 font-semibold text-xs mb-8">
        {translate("pleaseChooseCountryBank")}
      </div>
      <div className="mb-6">
        <CountrySelect
          handleSelect={(selected) => setSelectedCountry(selected)}
          referralCodeCountry={referralCodeCountry}
          promoCode={promoCode}
        />
      </div>
      <div className="mb-7">
        <label
          className="block text-gray-500 mt-4 text-xs font-semibold mb-2"
          htmlFor="promo-code"
        >
          {translate("enterPromoCodeSignUp")}
        </label>
        <div className="relative">
          <ReceiptTaxIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-slate-500" />
          <input
            className="shadow appearance-none border border-slate-200 focus:border-indigo-500 rounded w-full py-3 pl-10 pr-3 text-slate-900 text-sm font-semibold leading-tight focus:outline-none focus:shadow-outline"
            id="promo-code"
            type="text"
            value={promoCode}
            onChange={async (e) => {
              setPromoCode(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="w-[240px] m-auto">
        <div data-testid="next-button">
          <Button
            disabled={isDisabled}
            loading={loading}
            fluid
            onClick={createExpert}
          >
            {translate("next")}
          </Button>
        </div>
      </div>
      <Head>
        <title>{translate("signUpTitle")}</title>
      </Head>
    </div>
  );
}
