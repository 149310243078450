import { useRouter } from "next/router";

function ExpertNotSupported() {
  const router = useRouter();
  const handleClose = () => {
    router.replace({ query: {} });
  };

  return (
    <div className="relative h-auto">
      <div className="absolute top-1 right-1 z-10">
        <button
          type="button"
          className="inline-flex justify-center px-2 py-1 text-sm font-medium text-gray-200 bg-gray-900 border border-transparent rounded hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          onClick={handleClose}
        >
          X
        </button>
      </div>
      <iframe
        width="580"
        height="500"
        title="email updates"
        src="https://1cf70ffa.sibforms.com/serve/MUIEAAmas1O7WKskwSlgc9dYUqjJY35F92qQ6W86ZwV9ee4-utwvg8hoSqUFUfcDzV3pbwNT83Hvy3iLvKCCbKiyQhJjSNc6mfAnq2afVlklCsiWGjyHuxf42_towMt3Q6eT0Kh3kRd3QU-Kr5TRWdRoiWXXTU7V_tggHYCIAXo-q6GwPz--Vmrh4KNxpfYKX8RwspOMCthHR52v"
        frameBorder="0"
        allowFullScreen
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "100%",
        }}
      />
    </div>
  );
}

export default ExpertNotSupported;
